import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import * as types from "../../stores/types";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import classNames from "../../functions/classNames";
import { fetchOverview } from "../../functions/fetchData";
import { fetchDefaultCurrency } from "../../functions/currency";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import Link from "../../components/Link";
import Cities from "../../components/Cities";
import "./ErrorPage.css";

const Head = withI18n()(({ status, i18n }) => {
  const title = `${status} ${i18n._(t`Error`)}`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="ErrorPage__head">
        <div className="ErrorPage__text">
          {/* TODO: use more common class */}
          <h1 className="h1 m-32 m-64-t Bookings__title">
            <Trans>{title}</Trans>
          </h1>
          <p className="ErrorPage__subtitle">
            <Trans>
              Oops! Sorry, there is no such page. Could we help you select tours for your next trip?
            </Trans>
          </p>
        </div>
      </div>
    </>
  );
});

const NotFound = ({ status = 404, cities, topCities }) => {
  const lang = useCurrentLanguage();
  const overviewTopCities = cities || topCities;
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <Root stickyHeader searchInHeader language={lang} destinations={overviewTopCities}>
            <div className={classNames("Wrapper")}>
              <Head status={status} />
              {/* TODO: make getInitialProps working for http://localhost:3000/ru/chateau-de-versailles-a163/ */}
              {overviewTopCities ? (
                <div className="ErrorPage__content">
                  <h1 className="h1 m-32 m-64-t">
                    <Trans>Where will you go next?</Trans>
                  </h1>
                  <Cities external cities={overviewTopCities} />
                  <Link
                    external
                    arrow
                    className="Bookings__more"
                    to={`/${lang}/destinations`}
                    theme="heavy"
                  >
                    <Trans>Explore all destinations</Trans>
                  </Link>
                </div>
              ) : null}
            </div>
          </Root>
        );
      }}
    />
  );
};

NotFound.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, ...ctx }) => {
    const lang = getCurrentLanguage(match.params.lang);

    const cookies = get(req, "headers.cookie");
    store.dispatch({ type: types.FETCH_USER, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    let overview = {};
    overview = await fetchOverview({ lang }, req);

    return { cities: overview.topCities };
  },
);

const mapStateToProps = ({ overview }) => ({
  topCities: overview.topCities,
});

export default connect(mapStateToProps)(NotFound);
