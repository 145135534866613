import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { useMediaQuery } from "beautiful-react-hooks";
import isSSR from "../../constants/env/ssr";
import { availableLangs } from "../../functions/urlLanguage";
import { availableCurs } from "../../functions/currency";
import classNames from "../../functions/classNames";
import reverseUrl from "../../functions/reverseUrl";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { Icon } from "../Icon";
import Search from "../Search";
import Button from "../Button";
import sendEvent from "../../functions/analytics";
import UserAccount from "./UserAccount";
import CurrencyMenu from "./CurrencyMenu";
import HelpMenu from "./HelpMenu";
import LanguageMenu from "./LanguageMenu";
import "./Header.css";
import { getBaseUrl } from "../../functions/getBaseUrl";

const CreateTourButton = ({ isMobile, onClick }) => (
  <div className="button--create-tour">
    <Button
      icon={<Icon name="magic-stars" />}
      className="Header__button Header__button--create-tour"
      theme="accent-full"
      size={isMobile ? "M" : "S"}
      onClick={onClick}
    >
      {!isMobile && <Trans>Create Tour</Trans>}
    </Button>
  </div>
);

/**
 * Header for pages
 * @param {Object} $ - component props
 * @param {Object} $.user - current user
 * @param {Boolean} $.sticky - sticky CSS position
 * @param {Boolean} $.showSearch - display searchbar or not
 * @param {Boolean} $.language - to force language if needed
 */
const Header = withI18n()(
  ({
    sticky,
    showSearch,
    showHelp,
    minimal,
    language,
    i18n,
    currentCurrency: currency,
    isMainPage,
  }) => {
    const lang = useCurrentLanguage();
    const locale = language || lang;
    const [screen, setScreen] = useState(null);
    const [Dropdown, setDropdown] = useState(null);
    const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
    const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");

    let content = null;

    const isLangMenu = availableLangs.length > 1;
    // Don't show currency meny if there is no currentCurrency
    const isCurrMenu = availableCurs.length > 1 && currency;

    /**
     * Sets menu from outer UserAccount component
     * @param {ReactComponent?} Component - what to put into menu, if null no array will be put into Dropdown state
     */
    const setDropdownOuter = Component => setDropdown(Component ? ["account", Component] : null);

    const redirectUrl = new URL("/generate-your-tour", getBaseUrl());

    const handleOnClick = () => {
      sendEvent("track", "on_demand_store_header_button_clicked");
      window.location.assign(redirectUrl.toString());
    };

    const helpMenu = useMemo(
      () => <HelpMenu isMobile={isMobile} locale={locale} i18n={i18n} isDesktop={isDesktop} />,
      [i18n, isDesktop, isMobile, locale],
    );

    switch (screen) {
      case "search":
        content = (
          <>
            <Search
              themes={["inline", "xs-fullsize"]}
              placeholder={i18n._(t`City, attraction, tour name...`)}
            />
            <div className="col-xs Header__button Header__button--close">
              <Button
                hideTextOnTablet
                hideTextOnMobile
                theme="simple"
                iconPosition="before"
                icon={<Icon name="close/white" />}
                onClick={() => setScreen(null)}
              />
            </div>
          </>
        );
        break;
      default:
        content = (
          <div className={classNames("row between-xs middle-xs", "Header__content")}>
            <div className="col-xs-3 col-md-2 col-lg-2 Header__logo-container">
              <a
                href={reverseUrl("main", { lang: locale })}
                onClick={() => {
                  sendEvent("track", "click_on_logo_to_main");
                }}
              >
                <div className="Header__logo" />
              </a>
            </div>
            {showSearch ? (
              <div className="box Header__search">
                <Search
                  themes={["inline", "stroked", "werewolf"]}
                  placeholder={i18n._(t`City, attraction, tour name...`)}
                />
              </div>
            ) : null}
            <div className="row end-xs middle-xs Header__buttons">
              {minimal && showHelp && helpMenu}
              {!minimal && (
                <>
                  {isLangMenu && (
                    <LanguageMenu
                      isMobile={isMobile}
                      locale={locale}
                      i18n={i18n}
                      availableLangs={availableLangs}
                    />
                  )}
                  {isMainPage && <CreateTourButton isMobile={isMobile} onClick={handleOnClick} />}
                  {isCurrMenu && (
                    <CurrencyMenu isMobile={isMobile} currency={currency} i18n={i18n} />
                  )}
                  {helpMenu}
                  {!isMobile && (
                    <a
                      href={reverseUrl("favorites", { lang: locale })}
                      className="Header__button Header__button--favourites"
                    >
                      <Button
                        hideTextOnTablet
                        hideTextOnMobile
                        theme="simple"
                        iconPosition="before"
                        icon={<Icon name="heart" width="20" height="20" />}
                      >
                        <Trans>Favorites</Trans>
                      </Button>
                    </a>
                  )}
                  <UserAccount
                    setDropdown={setDropdownOuter}
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                  />
                  {isMobile && (
                    <Button
                      hideTextOnTablet
                      hideTextOnMobile
                      className="Header__button Header__button--search"
                      theme="simple"
                      iconPosition="before"
                      icon={<Icon name="search" />}
                      onClick={() => setScreen("search")}
                    />
                  )}
                </>
              )}
              {Dropdown && (
                <div className="col-xs Header__button Header__button--close-burger d-xs-only">
                  <Button
                    hideTextOnTablet
                    hideTextOnMobile
                    theme="simple"
                    iconPosition="before"
                    icon={<Icon name="close/white" />}
                    onClick={() => setDropdown(null)}
                  />
                </div>
              )}
            </div>
          </div>
        );
        break;
    }

    return (
      <header
        className={classNames(
          "Header",
          screen && `Header--${screen}`,
          sticky && "Header--sticky",
          minimal && "Header--minimal",
          ...(Dropdown ? ["Header--menu", `Header--${Dropdown[0]}`] : []),
        )}
      >
        <div className="Wrapper Header__content-wrapper">
          {content}
          {Dropdown ? <div className="Header__menu d-xs-only">{Dropdown[1]}</div> : null}
        </div>
      </header>
    );
  },
);

/**
 * Copy redux state into component props
 * @param {Object} state - redux state
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    currentCurrency: state.currencies.currentCurrency,
  };
}

export default connect(mapStateToProps)(Header);
