import get from "lodash/get";
import values from "lodash/values";
import { isSSR } from "../components/NoSSR";
// eslint-disable-next-line import/no-cycle
import { getProductLink } from "./getProductLink";
import DataLayer from "./DataLayer";
import Yandex from "./ymEcommerce";
import { calculateTotalValues } from "./objects/calculateTotalValues";

/**
 * Types of analytics interfaces
 * @type {Object}
 */
export const ANALYTICS = {
  YM: "ym", // Yandex.Metrika
  GA4: "ga4", // Google Analytics v4
  AMPL: "ampl", // Amplitude
  FBP: "fbp", // Facebook Pixel
  FCAPI: "fcapi", // Facebook Conversions API
  PINTEREST: "pinterest",
};

function getAnalyticsSystemsIds(includes) {
  return (includes || values(ANALYTICS)).join(" ");
}

/**
 * Wrapper for analytics sending interface
 * @param {String} method - which method of analytics to use, "track" by default
 * @param {String} eventName - will be set as analytics event name
 * @param {Object} info - will be passed into analytics event parameters
 * @param {Object} params - will be passed into interface. Add `include` for sending event to specific analytics systems
 */
export default function sendEvent(method, eventName, info, { include } = {}) {
  if (!isSSR) {
    try {
      const consent =
        typeof window !== "undefined"
          ? JSON.parse(window.localStorage.getItem("__unic_consent_mode")) || {}
          : {};
      const requiredConsents = [
        consent.functionality_storage,
        consent.personalization_storage,
        consent.security_storage,
        consent.analytics_storage,
      ];
      const hasRequiredConsents = requiredConsents.every(
        consentValue => consentValue === "granted",
      );
      if (!hasRequiredConsents) {
        console.log("Analytics consent not granted for required categories. Event not sent.");
        return;
      }
      console.log("Analytics push.");

      DataLayer.event("Analytics Reset Data", { analyticsEventProps: null });
      DataLayer.event("Analytics Send Event", {
        analyticsEventMethod: method,
        analyticsEventName: eventName,
        analyticsEventProps: info,
        analyticsEventSystems: getAnalyticsSystemsIds(
          include || [ANALYTICS.YM, ANALYTICS.GA4, ANALYTICS.AMPL],
        ),
      });

      if (method !== "track") return;
      if (eventName === "Product Viewed") {
        Yandex.productViewed(info);
      } else if (eventName === "Product Added") {
        Yandex.productAdded(info);
      } else if (eventName === "Product Removed") {
        Yandex.productRemoved(info);
      } else if (eventName === "Order Completed") {
        Yandex.orderCompleted(info);
      } else if (eventName === "Widget Product Click") {
        Yandex.widgetClickOnProduct();
      } else if (eventName === "Widget Click Show More") {
        Yandex.widgetClickShowMore();
      } else if (eventName === "Scrolled To Widget") {
        Yandex.scrolledToWidget();
      } else if (eventName === "Discount5: Topbar clicked") {
        Yandex.discount5TopbarClicked();
      } else if (eventName === "Discount5: Topbar closed") {
        Yandex.discount5TopbarClosed();
      } else if (eventName === "Discount5: Topbar started") {
        Yandex.discount5TopbarStarted();
      } else if (eventName === "Discount5: Topbar completed") {
        Yandex.discount5TopbarCompleted();
      } else if (eventName === "Ref Info") {
        Yandex.openRefundPopup();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Error in analytics with method ${method}`, info, err);
    }
  }
}

/**
 * Sends event to specified platforms
 * @param {Object} options - setup for current function usage
 * @param {Object} event - event description form `ANALYTICS_EVENTS`
 * @param  {...Array} info - will be passed as last arguments to the platform analytics function
 */
export function sendFacebookEvent(method, eventName, data = {}) {
  if (!isSSR) {
    sendEvent(method, eventName, data, { include: [ANALYTICS.FBP] });
  }
}

export function pageViewed() {
  if (!isSSR) {
    sendEvent(
      "page",
      "Page Viewed",
      {
        url: window.location.href,
        path: window.location.pathname,
        title: document.title,
      },
      {
        /* GA4 and YM creates this event on intialization */
        include: [ANALYTICS.AMPL, ANALYTICS.FBP],
      },
    );
  }
}

export function identify(userObj) {
  if (isSSR) {
    return;
  }

  const userProps = {
    id: userObj.id,
    phone: userObj.phone,
    email: userObj.email,
    firstName: userObj.first_name,
    lastName: userObj.last_name,
    username: userObj.nickname,
    isAffiliatePartner: userObj.is_sales,
  };

  sendEvent("identify", "User Identified", userProps, {
    include: [ANALYTICS.YM, ANALYTICS.GA4, ANALYTICS.AMPL, ANALYTICS.FBP],
  });
}

/**
 * Gets product type for Yandex.Metrika variant property
 * @param {Object} product - product object in original format
 */
const getProductVariant = product => {
  const isAnytime = !get(product, "tickets.0", false);
  const isWithTimeslots = get(product, "tickets.0.hasTimeslots", false);

  if (isAnytime) return "Anytime";
  if (isWithTimeslots) return "Timeslot";
  else if (!isWithTimeslots) return "Date";
  return null;
};

/**
 * Converts product description into format suitable with analytics interface
 * @param {Object} product - product object in original format
 * @param {Object} $ - additional info for generating product url
 * @param {Object} $.lang - current language
 * @param {Object} $.link - link, if already passed
 * @param {Number} $.position - position on products list
 * @return {Object}
 */
export function convertProduct(
  product,
  { lang, link, position, price: itemPrice, quantity = 1, coupon, currency = false },
  asItem = false,
) {
  const {
    id,
    title: name,
    preview,
    price: productPrice,
    category,
    categories,
    currencyCode,
  } = product;
  const productVariant = getProductVariant(product);

  const compiledCategory = Array.isArray(categories)
    ? categories.reduce((acc, c) => `${acc}${c.title}; `, "").trim()
    : category;

  let url = "";
  if (link) {
    url = link;
  } else if (lang) {
    url = getProductLink(lang, product);
  }

  const price = itemPrice || productPrice;

  return !asItem
    ? {
        product_id: id,
        name,
        image_url: preview,
        category: compiledCategory,
        quantity: quantity || 1,
        url,
        price,
        ...(typeof position === "number" ? { position } : {}),
        ...(currency && currencyCode ? { currency: currencyCode } : {}),
        ...(productVariant ? { variant: productVariant } : {}),
      }
    : {
        item_id: id,
        item_name: name,
        coupon,
        index: position,
        item_category: compiledCategory,
        ...(productVariant ? { item_variant: productVariant } : {}),
        price,
        quantity: quantity || 1,
      };
}

/**
 * Sends `Product List Viewed` event if list contains products
 * @param {String} id - list id
 * @param {Array[Object]} products - products from list
 * @param {String} lang - current language
 */
export function sendListViewedEvent(id, products, lang) {
  if (Array.isArray(products) && products.length) {
    const eventData = {
      list_id: id,
      products: products.map(product => convertProduct(product, { lang })),
    };

    sendEvent("track", "Product List Viewed", eventData);
  }
}

/**
 * Gets info from `product` to send into analytics
 * @param {Object} product - product description from server
 * @returns {Object} - basic info about product
 */
export function getProductBasicInfo(product) {
  return {
    id: product.id,
    product_category: product.category,
    name: product.title,
    price: product.price,
    currency: product.currencyCode,
    url: window.location.href,
    variant: get(product, "tickets.0", false) ? "timeslot" : "anytime",
  };
}

/**
 * Sends analytics event
 * @param {Object} product - product description from backend
 */
export function sendEventProductBasic(eventName, product) {
  sendEvent("track", eventName, getProductBasicInfo(product));
}

/**
 * Sends events for dynamic remarketing
 * @param {Object} product - product description from backend
 */
export function sendEventForRemarketing(eventName, product, finalPrice) {
  DataLayer.push({
    event: eventName,
    value: finalPrice || product.price,
    items: [
      {
        destination: product.id,
        google_business_vertical: "travel",
      },
    ],
  });
}

/**
 * Gets info from `product` to send into analytics
 * @param {Object} product - product description from server
 * @returns {Object} - basic info about product
 */
export function getPinterestProductInfo({ product, quantity }) {
  const productInfo = {
    product_id: product.id.toString(),
    product_name: product.title,
    product_price: product.price,
    product_category: product.category,
    product_quantity: quantity || 1,
  };

  return productInfo;
}

/**
 * Sends analytics event for Pinterest
 */
export function sendPinterestEvent(eventName, { product, quantity, totalPrice, orderId }) {
  const eventProps = { quantity: quantity || 1, currency: product.currencyCode };

  if (orderId) eventProps.order_id = orderId;
  if (totalPrice || product?.price) eventProps.value = totalPrice || product.price;
  if (product) eventProps.products = [getPinterestProductInfo({ product, quantity })];

  sendEvent("track", eventName, eventProps, { include: [ANALYTICS.PINTEREST] });
}

/**
 * Gets info about `product`
 * @param {Object} product - product description from server
 * @param {Object} categories - selected categories
 * @returns {Object} `product_category`, `name`, `category`, `quantity`
 */
export function getProductInfoShared(product, categories) {
  const selectedData = categories
    ? { category: JSON.stringify(categories), quantity: calculateTotalValues(categories) }
    : {};

  return {
    product_category: product.category,
    name: product.title,
    ...selectedData,
  };
}

export const sendEventTrack = (eventName, { userId, productId, ...props }) =>
  sendEvent("track", eventName, { user_id: userId, product_id: productId, ...props });
